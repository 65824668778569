import { CircularProgress, TextField, TextFieldProps } from "@material-ui/core";
import { useDistricts } from "./Data/useDistricts";
import { InputText } from "primereact/inputtext";
import { MessageToast, ToastMode } from "./Shared/MessageToast";

interface Props {
    substationId: number;
}

export function DefaultDistrictField(props: Props & TextFieldProps) {

    const defaultDistrict = useDistricts(props.substationId);

    if (defaultDistrict.isLoading) {
        return <CircularProgress size={24} />
    }

    else if (defaultDistrict.error) {
        return <MessageToast text={'Error getting District'} mode={ToastMode.Error} show />
    }
    else if (!defaultDistrict.data) {
        return <InputText className="w-100" value="No district found for the selected substation" disabled />
    }
    return <InputText className="w-100" value={defaultDistrict.data?.name} disabled />

}