import React from 'react';
import config from '../../../config';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import { SimpleSubstation } from '../interfaces/SimpleSubstation';

interface Props {
	onAuthError?: () => void;
}

export default class SimpleSubstationApis extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/Visnet/`;

	createSimpleSubstation = async (simpleSub: Partial<SimpleSubstation>): Promise<any> => {

		if (!simpleSub.transformerId) {
			const simpleSubstationJson = JSON.stringify({
				nameDescription: simpleSub.nameDescription,
				visNetSerial: simpleSub.serial,
				licenceArea: simpleSub.licenceAreaId,
				district: simpleSub.districtId
			});

			const apiAddress = `${SimpleSubstationApis.BASE_URL}substation/customer/${simpleSub.customerId}/simplesub`;
			return this.apiResponseHandler.post(apiAddress, simpleSubstationJson, false);
		}
		else {
			const apiAddress = `${SimpleSubstationApis.BASE_URL}commission/substation/
			${simpleSub.substationId}/visnet/${simpleSub.visNetId}/transformer/${simpleSub.transformerId}`;
			return this.apiResponseHandler.post(apiAddress, "", false);
		}
	}

}
