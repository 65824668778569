import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'react-bootstrap';
import { Divider } from '@material-ui/core';

interface Props {
	label: string;
	value: string | React.ReactNode;
	displayActionCell?: boolean;
	action?: React.ReactNode;
	disableDivider?: boolean;
	className?: string;
}

export function FormRow(props: Props) {
	return (
		<React.Fragment>
			<Row key={`formRow-${props.label}`} className={`${props.className} py-3 `}>
				<Col xs={12} sm={4} lg={6} key={`labelCell-${props.label}`} className={"d-flex align-items-center pl-sm-4"}>
					<Typography key={`valueText-${props.label}`} variant="subtitle2" color="textPrimary" >
						{props.label}
					</Typography>
				</Col>
				<Col key={`valueCell-${props.label}`} className={"d-flex align-items-center"}  >
					{props.value instanceof String
						? <Typography key={`valueText-${props.label}`} variant="body2" color="textSecondary" component="p" >
							{props.value}
						</Typography>
						: props.value
					}
				</Col>
				{props.displayActionCell
					&& <Col xs={1} key={`actionCell-${props.label}`} className={"d-flex align-items-center justify-content-center "} >
						{props.action}
					</Col>
				}
			</Row>
			{ !props.disableDivider && <Divider /> }
		</ React.Fragment>
	);
}