import * as React from 'react';
import { JwtManager } from './JwtManager';

export class TokenHelper extends React.PureComponent {
    tokenManager = new JwtManager({});
    token?: string | null;

    tokenIsValid(): boolean {
        this.token = this.tokenManager.getToken();
        var expiry = this.tokenManager.getExpires();
        if (!this.token || this.token.length === 0 || (expiry && new Date() > expiry)) {
            return false;
        }
        return true;
    }

    tokenExpiryIsValid(): boolean {
        var expiry = this.tokenManager.getExpires();
        return (expiry && new Date() > expiry) ? false : true;
    }

    /**
     * Check if the manager has access to a refresh token.
     */
    refreshTokenExists(): boolean {
        return this.tokenManager.getRefreshToken() != null;
    }
}