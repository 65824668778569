import React from 'react';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import config from '../../../config';
import { Asset } from '../interfaces/Asset';

interface Props {
    onAuthError?: () => void;
}

export default class AssetsAPIs extends React.PureComponent<Props> {
    private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });
    private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/Assets/children/`;

    getAssets = (substationId: number): Promise<Asset[]> => {
        const apiAddress = `${AssetsAPIs.BASE_URL}${substationId}/Assets`
        return this.apiResponseHandler.get(apiAddress);
    }

}