import * as React from 'react';
import config from '../../../config';
import {Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { InfoBanner, InfoMessage } from '../../Shared/Infobanner';
import './ForgotPasswordDialog.css';

interface ForgotPasswordDialogProps {
    showForgotPasswordDialog: boolean;
    onBackClick: any;
    plainFormView?: boolean;
    userEmail?: string;
}

interface ForgotPasswordDialogState {
    errorMessage?: '';
    email: string;
    submitted: boolean;
    loading: boolean;
    message?: InfoMessage;
    error?: boolean;
    agreedTermsAndConditions: boolean;
}

class ForgotPasswordDialog extends React.Component<ForgotPasswordDialogProps, ForgotPasswordDialogState>{   

    constructor(props: ForgotPasswordDialogProps)
    {
        super(props);
        this.state = {
            email: "",
            submitted: false,
            loading: false ,
            agreedTermsAndConditions: false      
        }
    }
    
    handleSubmit(e: any) {
        e.preventDefault();
        var state = this.state;
        var email = (this.props.userEmail)? this.props.userEmail: state.email;
        this.setState({ ...state,
            loading: true,
            submitted: true
        });

        // stop here if form is invalid
        if (email === "") {
            return;
        }

        var authEndpoint = config.apiGateway.AUTH_API + "/api/Account/initiateForgotPassword?email=" + email;
        fetch(authEndpoint,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .then(resp => this.handleResponse(resp))
            .catch(error => {
                this.setState({
                    ...this.state,
                    message: {
                        message: 'Forgot Password Error.',
                        warn: false,
                        show: true,
                        error: true
                    },
                    submitted: false,
                    loading: false,
                });
            });
    }

    async handleResponse(response: Response) {
        if (!response.ok) {
            this.setState({
                message: {
                    show: true,
                    warn: true,
                    message: await response.text(),
                    error: true
                },
                submitted: false,
                loading: false
            })
        } else {
            this.handleSuccess(response)
        }
    }

    handleSuccess(response: Response)
    {
        this.setState({agreedTermsAndConditions: false,
            loading: false,
            message: {
                show: true,
                warn: false,
                message: 'Email Sent.',
                successMessage:true,
                error: false
            },
            submitted: true
        })
    }

    handleChange(e: any) {
        const { name, value } = e.target;
        if(name === "email")
            this.setState({
                ...this.state,
                submitted: false,
                email: value,
                message: undefined
            });
    }

    modalView()
    {
        return  (
            <Modal show={this.props.showForgotPasswordDialog} backdrop="static">
            <Modal.Header className="custom-modal-header">
                <Modal.Title>{'Forgot Password'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleSubmit.bind(this)}> 
                <Modal.Body>
                    {this.renderFormBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width: "100%"}}>
                        <Col md="6" sm="6" lg="6">
                            <Button 
                                disabled={this.state.loading} 
                                className="blue-fill"
                                variant="primary" 
                                type="button"
                                onClick={this.props.onBackClick}
                                >
                                {'Back'}
                            </Button>
                        </Col>
                        {(!this.state.submitted || this.state.loading) &&
                        <Col md="6" sm="6" lg="6">
                            <Button 
                            disabled={this.state.loading} 
                            className="blue-fill"
                            variant="primary" 
                            type="submit" 
                            style={{float:"right"}}
                            >
                                {this.state.loading ? 'Please Wait' : 'Submit'}
                            </Button>
                        </Col>}
                    </Row>                 
                </Modal.Footer>
            </Form>
            </Modal>
        )
    }

    renderSimpleForm()
    {
        return <Form onSubmit={this.handleSubmit.bind(this)}> 
                
                <Row style={{width: "100%"}}>
                    <Col xs={12} lg={12}>
                        {this.renderFormBody()}
                    </Col>
                    {(!this.state.submitted || this.state.loading) &&
                    <Col style={{textAlign: 'center'}}>
                        <Button 
                        disabled={this.state.loading} 
                        className="blue-fill"
                        variant="primary" 
                        type="submit" 
                        >
                            {this.state.loading ? 'Please wait.' : 'Send password reset email'}
                        </Button>
                    </Col>}
                </Row>                   
        </Form>
    }

    renderFormBody()
    {
        return <React.Fragment>
                <Form.Group controlId="email">
                    <Form.Label>{'User Email'}</Form.Label>
                    <Form.Control name="email" required={true} type="email" placeholder={'Email'} disabled={this.props.userEmail ? true:false} value={(this.props.userEmail)? this.props.userEmail:this.state.email} onChange={this.handleChange.bind(this)} />
                </Form.Group>
                {(!this.state.message)?'':
                    <InfoBanner message={this.state.message}/>
                }
            </React.Fragment>
    }

    render() {
        return this.props.plainFormView? this.renderSimpleForm() : this.modalView()
    }

}

export default (ForgotPasswordDialog);
