import * as React from 'react';
import { JwtManager } from '../Shared/JwtManager';
import config from '../../config';
import { Modal, Button, Form, Row, Col, Nav } from 'react-bootstrap';
import { InfoBanner, InfoMessage } from '../Shared/Infobanner';

import RequestAccessDialog from './RequestAccess/RequestAccessDialog';
import ForgotPasswordDialog from './ForgotPassword/ForgotPasswordDialog';
import './LoginDialog.css';

interface State {
	username: string;
	password: string
	loginSuccess: boolean;
    loading: boolean;
    message?: InfoMessage;
    error?: boolean;
	showRequestAccessDialog: boolean;
    showForgotPasswordDialog: boolean;
}

interface Props {
	onAuthenticate: any;
	checkTokenIsValid: any;
	show: boolean;
	projectTitle?: string;
}

class LoginDialog extends React.Component<Props, State> {

	tokenManager = new JwtManager({});

	constructor(props: Props) {
		super(props);
		
		this.state = {
			username: "",
			password: "",
			message: {
				show: false,
				warn: false,
				message: "",
				error: false
			},
			loginSuccess: false,
			loading: false,
			showRequestAccessDialog: false,
			showForgotPasswordDialog: false
		};

	}

	handleUsernameChange = (event: any) => {
		this.setState({
			username: event.target.value,
			message: {
				show: false,
				warn: false,
				message: "",
				error: false
			}
		});
	}

	handlePasswordChange = (event: any) => {
		this.setState({
			password: event.target.value,
			message: {
				show: false,
				warn: false,
				message: "",
				error: false
			}
		});
	}

	handleSubmit = (event: any) => {
		this.setState({
			loading: true,
			message: {
				show: true,
				warn: false,
				message: "Login Submitted",
				error: false
			}
		});

		var authEndpoint = config.apiGateway.AUTH_API + "/api/token";
		var postBody = new URLSearchParams({
			grant_type: "password",
			username: this.state.username,
			password: this.state.password,
		});

		fetch(authEndpoint,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				},
				body: postBody
			})
			.then(resp => this.handleResponse(resp))
			.catch(error => {
				this.setState({
					message: {
						show: true,
						warn: true,
						message: 'Login Error',
						error: true
					},
					loading: false
				})
			});
		event.preventDefault();
	}

	handleResponse(response: Response) {
		if (response.status === 401) {
			this.setState({
				message: {
					show: true,
					warn: true,
					message: 'Invalid login credentials submitted.',
					error: true
				},
				loading: false
			});

		} else if (!response.ok) {

			this.setState({
				message: {
					show: true,
					warn: true,
					message: 'Login Error',
					error: true
				},
				loading: false
			})

		} else {
			this.handleSuccess(response);
		}
	}

	handleSuccess(response: Response) {
		response.json().then(resp => {
			this.tokenManager.storeAuthResponse(
				resp.token,
				resp.expires,
				resp.refresh_token
			);

			this.props.onAuthenticate(true);

			this.setState({
				password: "",
				message: {
					show: false,
					warn: false,
					message: "",
					error: false
				},
				loading: false
			});
		});
	}

	render() {

		return (
			<React.Fragment>
				<Modal show={this.props.show} backdrop="static">
					<Modal.Header className="custom-modal-header">
						<Modal.Title>{'Login'} - {'Simple Substation Installation'}</Modal.Title>
					</Modal.Header>
					<Form onSubmit={this.handleSubmit.bind(this)}>
						<Modal.Body>
							<Form.Group controlId="formBasicEmail">
								<Form.Label>{'Username'}</Form.Label>
								<Form.Control name="username" required={true} value={this.state.username} onChange={this.handleUsernameChange} type="text" placeholder={'Enter Username'} />
								<Form.Text className="text-muted">
								{'Substation 360 login credentials'}
                        </Form.Text>
							</Form.Group>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{'Password'}</Form.Label>
								<Form.Control name="password" required={true} type="password" placeholder={'Enter Password'} value={this.state.password} onChange={this.handlePasswordChange} />
							</Form.Group>
							{this.state.message && <InfoBanner message={this.state.message} />
							}
						</Modal.Body>
						<Modal.Footer>
							<Row style={{width: "100%"}}>
								<Col md="4" sm="4" lg="4">
									<Row>
										<Nav.Item>
											<Nav.Link onClick={() => this.setState({showRequestAccessDialog: true})} style={{padding: 0}}>{'Request Access'}</Nav.Link>
										</Nav.Item>
									</Row>
									<Row>
										<Nav.Item>
											<Nav.Link  onClick={() => this.setState({showForgotPasswordDialog: true})} style={{padding: 0}}>{'Forgot Password'}</Nav.Link>
										</Nav.Item>
									</Row>
								</Col>
								<Col className="d-flex justify-content-end">
									<Button
									disabled={this.state.loading}
									variant="primary"
									type="submit"
									>
										{this.state.loading ? 'Please Wait' : 'Login'}
									</Button>
								</Col>
							</Row>  
						</Modal.Footer>
					</Form>
					{this.state.showRequestAccessDialog && <RequestAccessDialog onBackClick={() => this.setState({showRequestAccessDialog: false})} showRequestAccessDialog={this.state.showRequestAccessDialog}/>}
            		{this.state.showForgotPasswordDialog && <ForgotPasswordDialog onBackClick={() => this.setState({showForgotPasswordDialog: false})} showForgotPasswordDialog={this.state.showForgotPasswordDialog}/>}
				</Modal>

			</React.Fragment >)

	}
}

export default (LoginDialog);