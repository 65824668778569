import * as React from "react";
import { useContext, useEffect, useReducer, useState } from "react";
import { Customer } from "../components/Shared/interfaces/Customer";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { CircularProgress, TextField } from "@mui/material";
import Button from "@material-ui/core/Button";
import { InputText } from "primereact/inputtext";
import { CustomerDropdown } from "../components/CustomerDropdown";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Card } from "../components/Shared/Card";
import {
  FormContainer,
  FormItem,
} from "../components/Shared/FormLayout/FormContainer";
import { SimpleSubstation } from "../components/Shared/interfaces/SimpleSubstation";
import { ProgressButton } from "../components/Shared/ProgressButton";
import { useSimpleSubstationCreation } from "../components/Data/SimpleSubstation/useSimpleSubstationCreation";
import { MessageToast, ToastMode } from "../components/Shared/MessageToast";
import { Asset } from "../components/Shared/interfaces/Asset";
import { ApiContext } from "../components/Contexts/ApiContext";
import { Snackbar } from "../components/Shared/Snackbar";
import Alert, { Color } from "@material-ui/lab/Alert";
import {
  Config,
  Feeder,
  RecloserRequestSchema,
  Substation,
} from "../components/Shared/interfaces/RecloserRequestSchema";
import { useSendRequest } from "../components/Data/SimpleSubstation/useSendRequest";
import { AuthContext } from "../components/Contexts/AuthContext";
import { SubstationVisNet } from "../components/Shared/interfaces/SubstationVisNet";
import { LicenceAreaDropdown } from "../components/LicenceAreasDropdown";
import { SubstationNameDropdown } from "../components/SubstationNameDropdown";
import { DistrictsDropdown } from "../components/DistrictsDropdown";
import { useCustomerRegionHierarchy } from "../components/Data/useCustomerRegionHierarchy";
import {
  CustomerRegionHierarchy,
  District,
  LicenceArea,
} from "../components/Shared/interfaces/CustomerRegionHierarchy";
import { TransformerDropdown } from "../components/TransformerDropdown";
import { PartialSubstation } from "../components/Shared/interfaces/PartialSubstation";
import { DefaultDistrictField } from "../components/DefaultDistrictField";

interface Props {
  checkTokenIsValid: any;
  open?: boolean;
  show: boolean;
  customer: Customer;
  customerSelected: boolean;
}

const initSelectedSubstation = {
  id: undefined,
  name: "",
};

export function Home(props: Props) {
  const apiContext = useContext(ApiContext);

  const customerSelectName = "Select a Customer";

  const nameMaxLength = 50;

  const [modalState, setModalState] = useState(false);

  const [showErrorToast, setShowErrorToast] = useState(false);
  const [customerErrorToastMessage, setCustomerErrorToastMessage] = useState<
    string | undefined
  >(undefined);
  const [serialErrorToastMessage, setSerialErrorToastMessage] = useState<
    string | undefined
  >(undefined);
  const [
    nameDescriptionErrorToastMessage,
    setNameDescriptionErrorToastMessage,
  ] = useState<string | undefined>(undefined);
  const [customerSerialErrorToastMessage, setCustomerSerialErrorToastMessage] =
    useState<string | undefined>(undefined);

  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerRegionHierarchy | undefined
  >(undefined);
  const [selectedLicenceArea, setSelectedLicenceArea] = useState<
    LicenceArea | undefined
  >(undefined);
  const [selectedDistrict, setSelectedDistrict] = useState<
    District | undefined
  >(undefined);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>("success");
  const [selectedSubstation, setSelectedSubstation] =
    useState<PartialSubstation>(initSelectedSubstation);
  const [selectedTransformer, setSelectedTransformer] = useState<
    Asset | undefined
  >(undefined);

  const [isSubStationNameSelected, setIsSubStationNameSelected] =
    useState<boolean>(false);

  const [newSimpleSub, setNewSimpleSub] = useReducer(
    (
      simpleSub: Partial<SimpleSubstation>,
      updatedSimpleSub: Partial<SimpleSubstation>
    ) => {
      return { ...simpleSub, ...updatedSimpleSub };
    },
    {}
  );
  const [showCustomerField, setShowCustomerField] = useState(true);
  const [isRefetchSubstationDropdown, setIsRefetchSubstationDropdown] =
    useState(false);

  function textInputTemplate(key: keyof SimpleSubstation, maxLength?: number) {
    return (
      <InputText
        key={`input-${key}`}
        name={key}
        value={newSimpleSub[key]?.toString() || ""}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        maxLength={maxLength}
      />
    );
  }

  function handleInputChange(event: any) {
    const name = event.target.name;
    const value = event.target.value;

    setNewSimpleSub({ ...newSimpleSub, [name]: value });
  }

  function handleAutocompleteTextInputChange(event: any) {
    setSelectedSubstation({
      id: undefined,
      name: event.target.value,
    });
    setNewSimpleSub({ ...newSimpleSub, nameDescription: event.target.value });
    setSelectedTransformer(undefined);
    setIsSubStationNameSelected(false);
  }

  function onAutocompleteSelect(_event: any, newValue: any) {
    setNewSimpleSub({
      ...newSimpleSub,
      substationId: undefined,
      nameDescription: "",
    });
    if (newValue && newValue.id) {
      setIsSubStationNameSelected(true);
      setSelectedSubstation({
        id: newValue.id,
        name: newValue.name,
      });
      setNewSimpleSub({
        ...newSimpleSub,
        substationId: newValue.id,
        nameDescription: newValue.name,
      });
    }
    // when user press enter for freetext, newvalue is passed as a string, not object
    else if (newValue && !newValue.id) {
      setSelectedSubstation({
        id: undefined,
        name: newValue,
      });
      setNewSimpleSub({ ...newSimpleSub, nameDescription: newValue });
      setIsSubStationNameSelected(false);
    }
  }

  function handleInputBlur(event: any) {
    checkCustomerSerialExists(
      newSimpleSub.serial as string,
      newSimpleSub.customerId as number
    );
  }

  const authContext = useContext(AuthContext);

  const userId = authContext.getUserId();

  const handleShowModal = () => {
    setModalState(true);
  };

  const handleHideModal = () => {
    setModalState(false);
  };

  const {
    isLoading: creatingRequest,
    mutate: sendRequest,
    isError: errorSendRequest,
    isSuccess: successSendRequest,
  } = useSendRequest();

  useEffect(() => {
    if (errorSendRequest) {
      setSnackbarText("There was an error submitting.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } else if (successSendRequest) {
      setSnackbarText("Submitted successfully.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      //refetch the substation name dropdown only if it is a new substation name
      setIsRefetchSubstationDropdown(!isSubStationNameSelected);
    }
  }, [errorSendRequest, successSendRequest]);

  const simpleSubstationCreate = useSimpleSubstationCreation({
    onSuccess: handleCreateSimpleSubstationSuccess,
    onError: handleCreateSimpleSubstationError,
  });

  function trimString(string: string, limit: number) {
    if (string.length > limit) {
      return string.substring(0, limit);
    } else {
      return string;
    }
  }

  function handleCreateSimpleSubstationSuccess(
    substationVisNet?: SubstationVisNet
  ) {
    const { substationId: substationId, visNetId: visNetId } =
      substationVisNet || {
        substationId: newSimpleSub.substationId,
        visNetId: newSimpleSub.visNetId,
      };

    const feeders: Feeder[] = [{ name: "Feeder 1" }];
    const config: Config[] = [
      { channel: 1, type: "feeder", feederIndex: 0 },
      { channel: 2, type: "feeder" },
      { channel: 3, type: "feeder" },
      { channel: 4, type: "feeder" },
      { channel: 5, type: "feeder" },
      { channel: 6, type: "feeder" },
    ];

    const substation: Substation = {
      name: trimString(newSimpleSub.nameDescription as string, nameMaxLength),
      simpleSubstationId: substationId,
      simpleSubstationDetails: newSimpleSub.nameDescription as string,
      serialNumber: newSimpleSub.serial as string,
      feeders: feeders,
      config: config,
    };
    if (selectedTransformer) {
      substation.transformerId = selectedTransformer.id;
      substation.transformerName = selectedTransformer.name;
    }

    const recloserRequestSchema: RecloserRequestSchema = {
      customerName: newSimpleSub.customerName as string,
      customerId: newSimpleSub.customerId as number,
      deviceId: visNetId,
      userId: userId,
      substation: substation,
    };

    var sendRequestSchemaJsonString = JSON.stringify({
      category: 4,
      requestJson: JSON.stringify(recloserRequestSchema),
    });

    sendRequest(sendRequestSchemaJsonString);
  }

  async function handleCreateSimpleSubstationError(err: unknown) {
    if (err instanceof Response) {
      err.text().then((errorMessage) => {
        setSnackbarText(errorMessage);
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      });
    } else if (err instanceof Error) {
      setSnackbarText(err.message);
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  }

  function handleCustomerSelect(customer?: CustomerRegionHierarchy) {
    newSimpleSub.customerId = customer?.id;
    newSimpleSub.customerName = customer?.name;
    setSelectedSubstation(initSelectedSubstation);
    setSelectedCustomer(customer);
    setSelectedLicenceArea(undefined);
    setSelectedDistrict(undefined);
  }

  function handleLicenceAreaSelect(licenceArea?: LicenceArea) {
    setSelectedLicenceArea(licenceArea);
    setSelectedDistrict(undefined);
  }

  function handleDistrictSelect(district?: District) {
    setSelectedDistrict(district);
  }

  function handleTransformerSelect(transformer?: Asset) {
    setSelectedTransformer(transformer);
  }

  useEffect(() => {
    setIsSubStationNameSelected(false);
    setSelectedLicenceArea(undefined);
    setSelectedTransformer(undefined);
    setNewSimpleSub({
      substationId: undefined,
      nameDescription: "",
    });
  }, [selectedCustomer]);

  const customerData = useCustomerRegionHierarchy();

  useEffect(() => {
    if (customerData.data && customerData.data.length === 1) {
      setShowCustomerField(false);
      handleCustomerSelect(customerData.data[0]);
    }
  }, [customerData.data]);

  const simpleSubInstallDetailsList: FormItem[] = [
    {
      label: "Customer *",
      value: customerData ? (
        <CustomerDropdown
          customerData={customerData}
          className={"w-100"}
          onSelect={handleCustomerSelect}
        />
      ) : null,
      hide: !showCustomerField,
    },
    {
      label: "Substation Name/Code *",
      value: newSimpleSub.customerId ? (
        <SubstationNameDropdown
          handleInputChange={onAutocompleteSelect}
          customerId={newSimpleSub.customerId}
          handlelTextInputChange={handleAutocompleteTextInputChange}
          selectedSubstationName={selectedSubstation.name}
          refreshData={isRefetchSubstationDropdown}
          setRefetchData={() => setIsRefetchSubstationDropdown(false)}
          newSubstationSubmitted={(createdSubstation: PartialSubstation) => {
            setIsSubStationNameSelected(true);
            setSelectedSubstation({
              name: createdSubstation.name,
              id: createdSubstation.id,
            });
          }}
        />
      ) : (
        <CircularProgress size={24} />
      ),
    },
    {
      label: "Transformer/Code",
      value: selectedSubstation.id ? (
        <TransformerDropdown
          substationId={selectedSubstation.id}
          onSelect={handleTransformerSelect}
          selectedValue={selectedTransformer}
        />
      ) : null,
      hide: !isSubStationNameSelected,
    },
    {
      label: "Licence Area",
      value:
        selectedCustomer?.licenceAreas &&
        selectedCustomer.licenceAreas.length > 0 ? (
          <LicenceAreaDropdown
            licenceAreas={selectedCustomer.licenceAreas.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            className="w-100"
            onSelect={handleLicenceAreaSelect}
            selectedValue={selectedLicenceArea}
            substationId={selectedSubstation.id}
          />
        ) : (
          <TextField label="No licence areas available" disabled />
        ),
      hide: !selectedCustomer,
    },
    {
      label: "District",
      value:
        selectedCustomer?.licenceAreas &&
        selectedLicenceArea?.districts &&
        selectedLicenceArea.districts.length > 0 ? (
          <DistrictsDropdown
            districts={selectedLicenceArea.districts.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            className="w-100"
            onSelect={handleDistrictSelect}
            selectedValue={selectedDistrict}
          />
        ) : (
          <TextField
            label="No districts available for the selected licence area"
            className="w-100"
            disabled
          />
        ),
      hide: !selectedLicenceArea || !!selectedSubstation.id,
    },
    {
      label: "District",
      value:
        selectedSubstation.id && isSubStationNameSelected ? (
          <DefaultDistrictField substationId={selectedSubstation.id} />
        ) : (
          <TextField label="No districts available" disabled />
        ),
      hide: !selectedSubstation.id || !selectedSubstation.name,
    },
    {
      label: "VisNet Hub Serial *",
      value: (
        <div style={{ display: "flex" }}>
          {" "}
          {textInputTemplate("serial")}{" "}
          <Button
            style={{ margin: "5px" }}
            variant={"contained"}
            type={"button"}
            className={"text-center"}
            color="primary"
            onClick={handleShowModal}
          >
            {"Scan QR"}
          </Button>
        </div>
      ),
    },
  ];

  function customerIsValid(): boolean {
    if (
      newSimpleSub.customerId == null ||
      newSimpleSub.customerId === 0 ||
      newSimpleSub.customerName == null ||
      newSimpleSub.customerName === customerSelectName
    )
      return false;
    return true;
  }

  function clearValidationMessages() {
    setCustomerErrorToastMessage(undefined);
    setSerialErrorToastMessage(undefined);
    setNameDescriptionErrorToastMessage(undefined);
    setCustomerSerialErrorToastMessage(undefined);
  }

  function checkCustomerSerialExists(
    serial: string,
    customerId: number
  ): Promise<boolean> {
    setCustomerSerialErrorToastMessage(undefined);
    return apiContext.visNetAPIs
      .getVisNetBySerialAndCustomer(serial, customerId)
      .then((asset: Asset) => {
        if (asset) {
          newSimpleSub.serial = serial;
          newSimpleSub.visNetId = asset.id;
          return true;
        }
        setCustomerSerialErrorToastMessage("Customer and serial don't match.");
        return false;
      });
  }

  async function handleCreateButtonClick() {
    clearValidationMessages();
    if (!isSubStationNameSelected) {
      newSimpleSub.substationId = undefined;
      newSimpleSub.transformerId = undefined;
    }
    if (!customerIsValid()) {
      setCustomerErrorToastMessage("Customer is required");
    }
    if (!newSimpleSub.serial) {
      setSerialErrorToastMessage("Serial is required");
    }
    if (
      newSimpleSub.nameDescription == null ||
      newSimpleSub.nameDescription === ""
    ) {
      setNameDescriptionErrorToastMessage("Name/Description is required");
    }
    if (
      customerIsValid() &&
      (await checkCustomerSerialExists(
        newSimpleSub.serial as string,
        newSimpleSub.customerId as number
      )) &&
      newSimpleSub.visNetId != null &&
      newSimpleSub.serial != null &&
      newSimpleSub.serial !== "" &&
      newSimpleSub.nameDescription != null &&
      newSimpleSub.nameDescription !== "" &&
      newSimpleSub.customerId !== null
    ) {
      setShowErrorToast(false);
      const subToSubmit: SimpleSubstation = {
        customerId: newSimpleSub.customerId as number,
        customerName: newSimpleSub.customerName as string,
        serial: newSimpleSub.serial,
        nameDescription: newSimpleSub.nameDescription,
        substationId: newSimpleSub.substationId,
        visNetId: newSimpleSub.visNetId,
        config: [],
        licenceAreaId: selectedLicenceArea?.id,
        districtId: selectedDistrict?.id,
        transformerId: selectedTransformer?.id,
      };
      createSimpleSubstation(subToSubmit);
    }
  }

  const createSimpleSubstation = (simpleSubstation: SimpleSubstation) => {
    setSnackbarText("Submitting...");
    setSnackbarSeverity("info");
    setShowSnackbar(true);

    simpleSubstationCreate.mutate(simpleSubstation);
  };

  function handleSnackbarClose() {
    setShowSnackbar(false);
  }

  const footer = (
    <div className="d-flex flex-row align-items-center justify-content-end">
      <ProgressButton
        onClick={handleCreateButtonClick}
        loading={simpleSubstationCreate.isLoading || creatingRequest}
      >
        Submit
      </ProgressButton>
    </div>
  );

  return (
    <React.Fragment>
      <Modal show={modalState}>
        <Modal.Header>
          <Modal.Title>{"Scan Serial QR"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BarcodeScannerComponent
            width={"100%"}
            height={"100%"}
            onUpdate={(err, result) => {
              if (result) {
                let resultJson = JSON.parse(result.getText());
                newSimpleSub.serial = resultJson.t;
                setModalState(false);
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={"button"}
            variant={"contained"}
            onClick={handleHideModal}
          >
            {"Hide QR"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col xl={12} className={"px-0"}>
            <Card title={"Install Simple Substation"} className={"mb-3"}>
              <FormContainer dataList={simpleSubInstallDetailsList} />
            </Card>
            <div>
              {showErrorToast && (
                <MessageToast
                  text={"Simple substation creation error"}
                  mode={ToastMode.Error}
                  show={showErrorToast}
                  autoHide
                />
              )}
              {customerErrorToastMessage && (
                <MessageToast
                  text={customerErrorToastMessage}
                  mode={ToastMode.Error}
                  show={customerErrorToastMessage != null}
                />
              )}
              {serialErrorToastMessage && (
                <MessageToast
                  text={serialErrorToastMessage}
                  mode={ToastMode.Error}
                  show={serialErrorToastMessage != null}
                />
              )}
              {nameDescriptionErrorToastMessage && (
                <MessageToast
                  text={nameDescriptionErrorToastMessage}
                  mode={ToastMode.Error}
                  show={nameDescriptionErrorToastMessage != null}
                />
              )}
              {customerSerialErrorToastMessage && (
                <MessageToast
                  text={customerSerialErrorToastMessage}
                  mode={ToastMode.Error}
                  show={customerSerialErrorToastMessage != null}
                />
              )}
            </div>
          </Col>
        </Row>
        <Snackbar open={showSnackbar} onClose={handleSnackbarClose}>
          <Alert severity={snackbarSeverity} variant="filled">
            {snackbarText}
          </Alert>
        </Snackbar>
        {footer}
      </Container>
    </React.Fragment>
  );
}
