import React from 'react';
import Routes from './routes';
import { AuthContextProvider } from './components/Contexts/AuthContext'
import { ApiContextProvider } from './components/Contexts/ApiContext'
import { QueryClientProvider, QueryClient } from "react-query";
import './scss/BootstrapTheme.scss'
import './scss/PrimeReactTheme.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const App: React.FC = () => {

	const queryClient = new QueryClient();
	
	return (
			<QueryClientProvider client={queryClient}>
				<AuthContextProvider>
					<ApiContextProvider>
							<Routes />
					</ApiContextProvider>
				</AuthContextProvider>
			</QueryClientProvider>
	);
}

export default App;
