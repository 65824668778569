import React, { useContext } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from './Contexts/AuthContext'

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},

		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			backgroundColor: theme.palette.darkBackground.main,
			color: theme.palette.darkBackground.contrastText
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,

		},
		appbarSpacer: {
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		divider: {
			backgroundColor: theme.palette.grey[600],
		},
		icon: {
			color: theme.palette.darkBackground.contrastText,
		},
		linkActive: {
			color: theme.palette.grey[500],
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',

		},
		listItemInactive: {
			borderLeftColor: "transparent",
			borderLeftStyle: "solid",
			borderLeftWidth: "5px",
			"&:hover": {
				color: theme.palette.darkBackground.contrastText,
				backgroundColor: theme.palette.darkBackground.light
			},

		},
		listItemActive: {
			borderLeftColor: theme.palette.primary.main,
			borderLeftStyle: "solid",
			borderLeftWidth: "5px",
		}

	}),
);


export default function NavDrawer() {
	const authContext = useContext(AuthContext);
	const location = useLocation();
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.root}>

			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.appbarSpacer} />

				{open ?
					<div className={classes.toolbar}>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'rtl' ? <ChevronRightIcon className={classes.icon} aria-label={"Close"} /> : <ChevronLeftIcon className={classes.icon} aria-label={"Close"} />}
						</IconButton>
					</div>
					: <IconButton
						aria-label={"Open"}
						onClick={handleDrawerOpen}
						className={classes.icon}
					>
						<MenuIcon className={classes.icon} />
					</IconButton>
				}

				<Divider className={classes.divider} />
				<List>
					<ListItem button key={"Logout"} onClick={authContext.logout} className={classes.listItemInactive}>
						<ListItemIcon><FontAwesomeIcon icon={faPowerOff} className={classes.icon} fixedWidth size="lg" /></ListItemIcon>
						<ListItemText primary={"Logout"} />
					</ListItem>

				</List>
			</Drawer>

		</div>
	);
}
