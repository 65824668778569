import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { usePartialSubstations } from "./Data/useSubstations";
import { useEffect, useState } from "react";
import { PartialSubstation } from "./Shared/interfaces/PartialSubstation";

interface Props {
  customerId: number;
  handleInputChange: (event: any, newValue: any) => void;
  handlelTextInputChange: (event: any) => void;
  selectedSubstationName: string;
  refreshData: boolean;
  setRefetchData: () => void;
  newSubstationSubmitted: (createdSubstation: PartialSubstation) => void;
}

export function SubstationNameDropdown(props: Props) {
  const [selectedOption, setSelectedOption] = useState<
    PartialSubstation | undefined
  >(undefined);
  const [dropdownData, setDropdownData] = useState<PartialSubstation[]>([]);
  const [openDropdown, setOpenDropdown] = useState(false);

  const substations = usePartialSubstations(
    props.customerId,
    props.selectedSubstationName
  );

  if (props.refreshData) {
    substations.refetch();
    props.setRefetchData();
  }

  useEffect(() => {
    if (!substations.isLoading && substations.data) {
      const matchingOption = substations.data?.find(
        (option) => option.name === props.selectedSubstationName
      );
      if (matchingOption) {
        setSelectedOption({
          name: matchingOption.name + " " + matchingOption.serialNumber,
          id: matchingOption.id,
        });
      }
    }
    if (selectedOption) {
      props.newSubstationSubmitted(selectedOption);
    }
  }, [substations.data, selectedOption]);

  useEffect(() => {
    if (substations.data) {
      const modifiedSubstationNames: PartialSubstation[] =
        substations.data?.map((substation) => {
          return {
            ...substation,
            name: `${substation.name} ${substation.serialNumber}`,
          };
        });

      setDropdownData(modifiedSubstationNames ?? []);
    }
  }, [substations.data]);

  useEffect(() => {
    if (props.customerId) {
      setDropdownData([]);
    }
  }, [props.customerId]);

  return (
    <Autocomplete
      id="substationDropdown"
      freeSolo
      sx={{ width: 300 }}
      open={openDropdown}
      onOpen={() => {
        setOpenDropdown(true);
      }}
      onClose={() => {
        setOpenDropdown(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option != null && value != null && option.name === value.name
      }
      getOptionLabel={(option) => option?.name ?? props.selectedSubstationName}
      onChange={props.handleInputChange}
      options={dropdownData}
      loading={substations.isLoading}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Substation"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {substations.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onChange={props.handlelTextInputChange}
        />
      )}
    />
  );
}
