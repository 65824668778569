import {QueryCallbacks} from "../../Shared/interfaces/ReactQuery";
import {ApiContext} from "../../Contexts/ApiContext";
import {useContext} from "react";
import {useMutation} from "react-query";


export function useSendRequest(callbacks?: QueryCallbacks<number, number>){
    const apiContext = useContext(ApiContext);
    
    return useMutation(
        ["sendRequest"],
        async (sendRequestSchema:string) => apiContext.requestAPIs.sendRequest(sendRequestSchema),
        {
            onSuccess: callbacks?.onSuccess,
            onError: callbacks?.onError
        }
    );
}