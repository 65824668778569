import {useState, useEffect, useContext} from 'react';
import clsx from 'clsx';
import {Dropdown, DropdownProps} from "primereact/dropdown";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {MessageToast, ToastMode} from './Shared/MessageToast';
import {Customer} from './Shared/interfaces/Customer';
import {AuthContext} from "./Contexts/AuthContext";
import { UseQueryResult } from 'react-query';
import { CustomerRegionHierarchy } from './Shared/interfaces/CustomerRegionHierarchy';

interface Props {
    customerData: UseQueryResult<CustomerRegionHierarchy[], unknown>
    onSelect: (customer?: CustomerRegionHierarchy) => void;
    invalid?: boolean;
    invalidMessage?: string;
    displaySingleCustomerName?: boolean;
}

export function CustomerDropdown(props: Props & DropdownProps) {

    const { onSelect, customerData: customers, ...rest } = props;

    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);

    useEffect(() => {
        if (customers.data && customers.data.length > 1) {
           customers.data.sort((a, b) => a.name.localeCompare(b.name));
            setSelectedCustomer(customers.data[0]);
            onSelect(customers.data[0]);
        }
    }, [customers.data]);
    
    function handleChange(e: any) {
        setSelectedCustomer(e.value);
        onSelect(e.value);
    }

    if (customers.isLoading) {
        return <CircularProgress size={24}/>
    } else if (customers.error) {
        return <MessageToast text={'Error getting customers'} mode={ToastMode.Error} show/>
    } else return <div className="d-flex justify-content-start">
        <div className="d-flex flex-column">
            { customers.data && customers.data?.length === 1 ? null :
                    <Dropdown
                        className={clsx({"p-invalid": props.invalid})}
                        value={selectedCustomer}
                        options={customers.data}
                        onChange={handleChange}
                        optionLabel="name"
                        filter
                        filterBy="name"
                        placeholder={"Please select a customer"}
                        {...rest}
                    />}
            {props.invalid
                && props.invalidMessage
                && <Typography variant="caption" display="block" gutterBottom className="p-error pl-1">
                    {props.invalidMessage}
                </Typography>
            }
        </div>
    </div>
}