import { useQuery } from "react-query";
import { ApiContext } from "../Contexts/ApiContext";
import { useContext } from "react";

export function usePartialSubstations(
  customerId: number,
  substationName: string
) {
  const apiContext = useContext(ApiContext);

  return useQuery(
    ["partialSubstation", customerId, substationName],
    async () => {
      if (customerId && substationName != "")
        return await apiContext.substationAPIs.getPartialSubstations(
          customerId,
          substationName
        );
      return undefined;
    }
  );
}
