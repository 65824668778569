import { useContext, useCallback } from "react";
import { ApiContext } from "../../Contexts/ApiContext";
import { SimpleSubstation } from "../../Shared/interfaces/SimpleSubstation";
import {QueryCallbacks} from "../../Shared/interfaces/ReactQuery"
import {useMutation} from "react-query";
import {SubstationVisNet} from "../../Shared/interfaces/SubstationVisNet";

export function useSimpleSubstationCreation(callbacks?: QueryCallbacks<SubstationVisNet, Response>) {
	const apiContext = useContext(ApiContext);
	
	return useMutation(
		["createSimpleSubstation"],
		async (simpleSubstation: SimpleSubstation) => apiContext.simpleSubstationApis.createSimpleSubstation(simpleSubstation),
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
		}
	);
}