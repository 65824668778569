import React from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';

declare module "@material-ui/core/styles/createPalette" {
	interface Palette {
		eaPurple: Palette['primary'];
		darkBackground: Palette['primary'];
		lightBackground: Palette['primary'];
		redStatus: Palette['primary'];
		amberStatus: Palette['primary'];
		greenStatus: Palette['primary'];
	}
	interface PaletteOptions {
		eaPurple: PaletteOptions['primary'];
		darkBackground: PaletteOptions['primary'];
		lightBackground: PaletteOptions['primary'];
		redStatus: PaletteOptions['primary'];
		amberStatus: PaletteOptions['primary'];
		greenStatus: PaletteOptions['primary'];
	}
}

const theme = createMuiTheme({
	zIndex: {
		drawer: 900
	},
	palette: {
		primary: {
			main: "#00AAe4",
			contrastText: "#fff"
		},
		eaPurple: {
			main: '#220639',
			contrastText: "#fff"
		},
		darkBackground: {
			main: grey["A400"],
			light: '#363636',
			contrastText: grey[300]
		},
		lightBackground: {
			main: grey[200],
			light: grey[50],
			contrastText: grey[300]
		},
		success: {
			main: "#1C9D5C"
		},
		warning: {
			main: "#FAB722"
		},
		error: {
			main: "#E51E3D"
		},
		redStatus: {
			main: "#E51E3D"
		},
		amberStatus: {
			main: "#FAB722"
		},
		greenStatus: {
			main: "#1C9D5C"
		}
	},
	typography: {
		fontFamily: [
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	props: {
		MuiButton: {
		  disableElevation: true
	   }
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: "capitalize",
				fontWeight: 400,
			}
		},
		MuiList: {
			padding: {
				paddingTop: "2px",

			}
		},
		MuiMenuItem: {
			root: {
			  background: '#fff',
			  '&$selected': { 
				backgroundColor: '#E3F2FD'
			  }
			}
		  }
	}
});

interface Props {
	children?: React.ReactNode;
}

export function MuiThemeEA(props: Props) {
	return (
		<ThemeProvider theme={theme}>
			{props.children}
		</ThemeProvider>
	);
}