import { Dropdown, DropdownProps } from "primereact/dropdown";
import { Asset } from "./Shared/interfaces/Asset";
import { useAssets } from "./Data/useAssets";
import { CircularProgress, TextField } from "@mui/material";
import { MessageToast, ToastMode } from "./Shared/MessageToast";
import { useEffect, useState } from "react";

interface Props {
  substationId: number;
  onSelect: (Asset?: Asset) => void;
  selectedValue: Asset | undefined;
}
export function TransformerDropdown(props: Props & DropdownProps) {
  const { onSelect, substationId, selectedValue, ...rest } = props;
  const [transformers, SetTransformers] = useState<Asset[]>();

  const assets = useAssets(substationId);

  useEffect(() => {
    if (assets.data) {
      let transformerData = assets.data.filter(
        (asset) => asset.assetTypeName === "Transformer"
      );
      transformerData = transformerData.map((transformer) => {
        transformer.name = `${transformer.name} ${transformer.assetTag}`;
        return transformer;
      });
      SetTransformers(transformerData);
    }
  }, [assets.data]);

  function handleChange(e: DropdownProps) {
    onSelect(e.value);
  }
  if (assets.isLoading) {
    return <CircularProgress size={24} />;
  }
  if (assets.error) {
    return (
      <MessageToast
        text={"Error getting Transformers"}
        mode={ToastMode.Error}
        show
      />
    );
  }
  if (!assets.data || assets.data.length === 0) {
    return (
      <TextField label="No transformers available" className="w-100" disabled />
    );
  }

  if (!transformers || transformers.length === 0) {
    return (
      <TextField label="No transformers available" className="w-100" disabled />
    );
  }

  if (transformers.length === 1) {
    onSelect(transformers[0]);
    return (
      <TextField label={transformers[0].name} className="w-100" disabled />
    );
  }
  return (
    <div className="d-flex justify-content-start">
      <div className="d-flex flex-column">
        <Dropdown
          placeholder={"Please select a Transformer"}
          options={transformers}
          optionLabel="name"
          filter
          filterBy="name"
          value={selectedValue}
          onChange={handleChange}
          {...rest}
        ></Dropdown>
      </div>
    </div>
  );
}
