import * as React from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import config from '../../../config';
import { InfoBanner, InfoMessage } from '../../Shared/Infobanner';
import { UserRequestJson } from '../LoginInterfaces';
import './RequestAccessDialog.css';

interface RequestAccessDialogProps {
    showRequestAccessDialog: boolean;
    onBackClick: any;
}

interface RequestAccessDialogState {
    username: string;
    email: string;
    submitted: boolean;
    loading: boolean;
    message?: InfoMessage;
    error?: boolean;
    agreedTermsAndConditions: boolean;
    requestText: string;
}

class RequestAccessDialog extends React.Component<RequestAccessDialogProps, RequestAccessDialogState>{   

    constructor(props: RequestAccessDialogProps)
    {
        super(props);
        this.state = {
            username: "",
            email: "",
            submitted: false,
            loading: false ,
            agreedTermsAndConditions: false,
            requestText: ""    
        }
    }

    handleSubmit(e: any) {
        e.preventDefault();
        var state = this.state;
        var email = state.email;
        this.setState({ ...state,
            loading: true,
            submitted: true
        });

        // stop here if form is invalid
        if (email === "") {
            return;
        }

        var requestEndpoint = config.apiGateway.REQUEST_API + "/api/requests/userrequest";

        var newUserRequestJson = JSON.stringify({
            "category": 3,
            "requestJson": JSON.stringify(this.getNewUserSchema(email, this.state.requestText))
        });

		fetch(requestEndpoint,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: newUserRequestJson
			}).then((resp : any) => this.handleSuccess(resp))
            .catch((error : any) => {
                this.setState({
                    ...this.state,
                    message: {
                        message: 'Error requesting access.',
                        warn: false,
                        show: true,
                        error: true
                    },
                    submitted: false,
                    loading: false,
                });
            });
    }

    getNewUserSchema(email: string, requestText: string)
    {
        var userRequestJson: UserRequestJson = {
            username: email.split("@")[0],
            email: email,
            requestText: requestText
        }
        return userRequestJson;
    }

    async handleSuccess(response: Response)
    {
        if(response.ok){
            this.setState({agreedTermsAndConditions: false,
                loading: false,
                message: {
                    show: true,
                    warn: false,
                    message: <p style={{textAlign: 'center'}}> An email will be sent to the provided email address with further instructions. </p>,
                    successMessage: true,
                    error: false
                },
                submitted: true,
                requestText: "",
                username: "",
                email: "", 
            })
        } else{
            this.setState({
                ...this.state,
                message: {
                    message: await response.text(),
                    warn: false,
                    show: true,
                    error: true
                },
                submitted: false,
                loading: false,
            });
        }
    }

    handleChange(e: any) {
        const { name, value } = e.target;
        if(name === "username")
           this.setState({
            ...this.state,
            submitted: false,
            username: value,
            message: undefined
           });
        else if(name === "email")
            this.setState({
                ...this.state,
                submitted: false,
                email: value,
                message: undefined
            });
        else if(name === "requestText")
            this.setState({
                ...this.state,
                submitted: false,
                requestText: value,
                message: undefined
        });
    }

    render() {
        return (
            <Modal show={this.props.showRequestAccessDialog} backdrop="static">
            <Modal.Header className="custom-modal-header">
            <Modal.Title>{'Request Access'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleSubmit.bind(this)}> 
                <Modal.Body>
                    <Form.Group controlId="email">
                        <Form.Label>{'Email Address'}</Form.Label>
                        <Form.Control name="email" required={true} type="email" placeholder={'Email'} value={this.state.email} onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>{'Comments'}</Form.Label>
                        <Form.Control name="requestText" as="textarea" placeholder={'Request Comments'} value={this.state.requestText} onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    {(!this.state.message)?'':
                        <InfoBanner message={this.state.message}/>
                    } 
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{width: "100%"}}>
                         <Col md="6" sm="6" lg="6">
                            <Button 
                                disabled={this.state.loading} 
                                className="blue-fill"
                                variant="primary" 
                                type="button"
                                onClick={this.props.onBackClick}
                                >
                                {'Back'}
                            </Button>
                        </Col>
                        {(!this.state.submitted || this.state.loading) &&
                        <Col md="6" sm="6" lg="6">
                            <Button 
                                disabled={this.state.loading} 
                                className="blue-fill"
                                variant="primary" 
                                type="submit" 
                                style={{float:"right"}}
                                >
                                {this.state.loading ? 'Please wait' : 'Request Access'}
                            </Button>
                        </Col>}
                    </Row>                   
                </Modal.Footer>
            </Form>
            </Modal>
        ) 
    }
}

export default (RequestAccessDialog);