import { useQuery } from "react-query";
import { ApiContext } from "../Contexts/ApiContext";
import { useContext } from "react";
import { AuthContext } from "../Contexts/AuthContext";

export function useCustomerRegionHierarchy() {
    const apiContext = useContext(ApiContext);
    const authContext = useContext(AuthContext);

    return useQuery(
        ["CustomerRegionHierarchy", authContext.getUserId()],
        async () => {
            return await apiContext.customerApis.getCustomerRegionHierarchy();
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    );
}