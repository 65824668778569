import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({

		root: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		buttonDisabled: {
			backgroundColor: `${theme.palette.grey[400]}!important`,
			borderColor:  `${theme.palette.grey[400]}!important`
		  },
		buttonProgress: {

			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -10,
			marginLeft: -12,
		},
		buttonColor: {

		}
	}),
);

interface Props {
	loading: boolean;
	children?: React.ReactNode;
	submitType?: boolean;
}

export function ProgressButton(props: Props & ButtonProps ) {
	const classes = useStyles();

	const buttonClassname = clsx({
		[classes.buttonDisabled]: props.loading,
	  });

	  const {loading, children, ...rest} = props;

	return (
		<span className={classes.root}>

				<Button
					variant="contained" 
					type={props.submitType ? "submit" : undefined }
					color="primary"
					className={buttonClassname}
					disabled={props.loading}
					{...rest}
				>
					{props.children}
				</Button>
				{props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
		</span>
	);
}
