import * as React from 'react';
import TokenRefresher from '../TokenRefresher';
import { JwtManager } from '../JwtManager';
import { TokenHelper } from "../TokenHelper";

interface Props {
	onAuthError?: () => void;
}
export class ApiHandlerPureComponent extends React.PureComponent<Props> {

	tokenRefresher = new TokenRefresher({});
	tokenManager = new JwtManager({});
	tokenHelper = new TokenHelper({});

	getToken = () => this.tokenManager.getToken();

	handleAuthError(): Promise<any> {
		this.props.onAuthError && this.props.onAuthError();

		return Promise.resolve();
	}

	get(address: string): Promise<any> {
		return this.query(address, "GET");
	}

	post(address: string, body?: string, errorNotAsString?: boolean): Promise<any> {
		return this.query(address, "POST", body, errorNotAsString);
	}

	postFile(address: string, body: File | FormData): Promise<any> {
		this.setState({
			loading: true
		});
		return fetch(address,
			{
				method: "POST",
				headers: {
					"Authorization": `Bearer ${this.getToken()}`
				},
				body: body,
			})
			.then(response => {
				return this.handleResponse(response, address, "POST", body);
			})
			.catch(error => {
				console.error(error);
				return Promise.reject(error);
			});
	}

	put(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
		return this.query(address, "PUT", body, undefined, removeHeaderContentType);
	}

	patch(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
		return this.query(address, "PATCH", body, undefined, removeHeaderContentType);
	}

	delete(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
		return this.query(address, "DELETE", body, undefined, removeHeaderContentType);
	}

	query(address: string, method: string, body?: string | FormData, errorNotAsString?: boolean, removeHeaderContentType?: boolean): Promise<any> {
		const tokenIsValid = this.tokenHelper.tokenIsValid();
		if (!tokenIsValid) {
			return this.handleAuthError();
		}

		const token = this.getToken();

		let header: {};
		header = {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		};
		if (removeHeaderContentType) {
			header = {
				"Authorization": `Bearer ${token}`
			}
		}

		return fetch(address,
			{
				method: method,
				headers: header,
				body: body
			})
			.then(response => {
				return this.handleResponse(response, address, method, body);
			})
			.catch(error => {
				return Promise.reject(error);
			});
	}

	handleResponse(response: Response, address: string, method: string, body?: string | File | FormData) {
		if (response.ok) {
			const contentType = response.headers.get('Content-Type');

			if (contentType && contentType.includes("application/json")) {
				return response.json().then(data => {
					return data || Promise.resolve(undefined);
				});
			}
			else if (contentType && contentType.includes("application/octet-stream")) {
				return response.blob().then(data => {
					return data || Promise.resolve(undefined);
				});
			}
			else {
				return response.text().then(text => {
					return text || Promise.resolve(undefined);
				});
			}
		} else {
			if (response.status === 401 ) {
				var expiry = this.tokenManager.getExpires();
				if (expiry && new Date() > expiry) {
					// Token has expired, try a refresh
					return this.tokenRefresher.refreshToken()
						.then(() => {
							// Update the token and recursively try again.
							if (body instanceof File || body instanceof FormData) {
								return this.postFile(address, body);
							} else {
								return this.query(address, method, body);
							}
						})
						.catch(() => {
							return this.handleAuthError();
						});
				} else {
					return this.handleAuthError();
				}
			} else {
				return Promise.reject(response);
			}
		}
	}

	getErrorString(error: any, errorNotAsString?: boolean): string {
		if (errorNotAsString)
			return error;

		if (error.constructor === String) {
			return (error as string);
		}
		if (error instanceof Response) {
			var errorResponse = error as Response;
			return errorResponse.statusText;
		}
		return error.toString();
	}
}