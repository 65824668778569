import {ApiHandlerPureComponent} from "./ApiHandlerPureComponent";
import config from "../../../config";
import React from "react";

interface Props {
    onAuthError?: () => void;
}

export default class RequestAPIs extends React.PureComponent<Props> {
    private apiResponseHanlder = new ApiHandlerPureComponent({onAuthError: this.props.onAuthError});
    
    private static readonly BASE_URL: string = `${config.apiGateway.REQUEST_API}/api/Requests/`;

    sendRequest = (sendRequestSchema: string): Promise<number> => {
        
        var apiAddress = `${RequestAPIs.BASE_URL}`;
        
        return this.apiResponseHanlder.post(apiAddress, sendRequestSchema, false);
    }
    
}

