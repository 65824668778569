import React from 'react';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import config from '../../../config';
import { LicenceArea } from '../interfaces/LicenceArea';

interface Props {
    onAuthError?: () => void;
}

export default class LicenceAreasApis extends React.PureComponent<Props> {
    private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });
    private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/LicenceAreas/parent/asset/`;

    getDefaultLicenceArea = (substationId?: number): Promise<LicenceArea> => {

        const apiAddress = `${LicenceAreasApis.BASE_URL}${substationId}`;

        return this.apiResponseHandler.get(apiAddress);
    }
}