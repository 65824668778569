import React, { useContext } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AuthContext } from './components/Contexts/AuthContext';
import { MuiThemeEA } from './components/Contexts/MuiThemeEA';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './components/Header'
import NavDrawer from './components/NavDrawer'
import LoginDialog from './components/Users/LoginDialog'

interface Props {
	children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appbarSpacer: {
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		navDrawerSpacer: {
			// necessary for content to be to the right of the closed nav drawer
			marginLeft: theme.spacing(7) + 1,
		},
		content: {
			padding: theme.spacing(3),
			overflow: "auto",
			height: `calc(100vh - 56px)`,
			[`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
				height: `calc(100vh - 48px)`
			},
			[theme.breakpoints.up("sm")]: {
				height: `calc(100vh - 64px)`
			}
		},
	}),
);
export function Layout(props: Props) {
	const authContext = useContext(AuthContext);
	const classes = useStyles();

	return (

		<React.Fragment>
			<CssBaseline />
			<MuiThemeEA>
				<LoginDialog
					checkTokenIsValid={authContext.checkTokenIsValid}
					onAuthenticate={authContext.setUserIsAuthenticated}
					show={!authContext.userIsAuthenticated}
					projectTitle={"Project Title Placeholder"}
				/>
				<Header />
				<NavDrawer />
				{authContext.userIsAuthenticated &&
					<main className={clsx(classes.navDrawerSpacer)}  >
						<div className={classes.appbarSpacer} />
						<div className={classes.content} >
							{props.children}
						</div>
					</main>
				}
			</MuiThemeEA>
		</React.Fragment>
	);
}
