import { JwtManager } from "./components/Shared/JwtManager";
import {
  Devices,
  visNetModel,
  guardModel,
  visNetModelId,
  guardModelId,
} from "./components/DefaultDataSet/Devices";

var route = window.location.pathname;
var route1 = route.split("/")[1];
var ssHardwareSettingsFile = "hardware_settings.json";
const customerVisNetConfigurator = "CustomerVisNetConfigurator";
const customerGuardConfigurator = "CustomerGuardConfigurator";
const visNetConfigurator = "VisNetConfigurator";
const guardConfigurator = "GuardConfigurator";

const dev = {
  apiGateway: {
    AUTH_API: "https://auth.sub360test.co.uk",
    META_API: "https://meta.sub360test.co.uk",
    DATA_API: "https://data.sub360test.co.uk",
    CONFIG_API: "https://lvcap.sub360test.co.uk",
    REQUEST_API: "https://requests.sub360test.co.uk",
    IMAGES_API: "https://images.sub360test.co.uk",
    WORK_API: "https://work.sub360test.co.uk",
    UTP2_API: "https://utp2.sub360test.co.uk",
    MQTT_SETTING_KEY: "test",
  },
  // deviceType: deviceType,
  adminClaim: "IsAdmin",
  customerGuardConfiguratorClaim: customerGuardConfigurator,
  customerVisnetConfiguratorClaim: customerVisNetConfigurator,
  configuratorClaim: "configurator",
  viewAllClaim: "viewAll",
  customerView: "customerView",
  assetNameMaxCharacterLength: 50,
  defaultLocationId: 100000,
  ssHardwareSettingsFile: ssHardwareSettingsFile,
  visNetModel: visNetModel,
  guardModel: guardModel,
  visNetConfigurator: visNetConfigurator,
  guardConfigurator: guardConfigurator,
  visNetModelId: visNetModelId,
  guardModelId: guardModelId,
};

const prod = {
  apiGateway: {
    AUTH_API: "https://auth.substation360.co.uk",
    META_API: "https://meta.substation360.co.uk",
    DATA_API: "https://data.substation360.co.uk",
    CONFIG_API: "https://lvcap.substation360.co.uk",
    REQUEST_API: "https://requests.substation360.co.uk",
    IMAGES_API: "https://images.substation360.co.uk",
    WORK_API: "https://work.substation360.co.uk",
    UTP2_API: "https://utp2.substation360.co.uk",
    MQTT_SETTING_KEY: "test",
  },
  // deviceType: deviceType,
  adminClaim: "IsAdmin",
  customerGuardConfiguratorClaim: customerGuardConfigurator,
  customerVisnetConfiguratorClaim: customerVisNetConfigurator,
  configuratorClaim: "configurator",
  viewAllClaim: "viewAll",
  customerView: "customerView",
  assetNameMaxCharacterLength: 50,
  defaultLocationId: 100000,
  ssHardwareSettingsFile: ssHardwareSettingsFile,
  visNetModel: visNetModel,
  guardModel: guardModel,
  visNetConfigurator: visNetConfigurator,
  guardConfigurator: guardConfigurator,
  visNetModelId: visNetModelId,
  guardModelId: guardModelId,
};

const beta = {
  apiGateway: {
    AUTH_API: "https://auth-beta.substation360.co.uk",
    META_API: "https://meta-beta.substation360.co.uk",
    DATA_API: "https://data-beta.substation360.co.uk",
    CONFIG_API: "https://lvcap-beta.substation360.co.uk",
    REQUEST_API: "https://requests-beta.substation360.co.uk",
    IMAGES_API: "https://images-beta.substation360.co.uk",
    WORK_API: "https://work-beta.substation360.co.uk",
    UTP2_API: "https://utp2-beta.substation360.co.uk",
    MQTT_SETTING_KEY: "test",
  },
  // deviceType: deviceType,
  adminClaim: "IsAdmin",
  customerGuardConfiguratorClaim: customerGuardConfigurator,
  customerVisnetConfiguratorClaim: customerVisNetConfigurator,
  configuratorClaim: "configurator",
  viewAllClaim: "viewAll",
  customerView: "customerView",
  assetNameMaxCharacterLength: 50,
  defaultLocationId: 100000,
  ssHardwareSettingsFile: ssHardwareSettingsFile,
  visNetModel: visNetModel,
  guardModel: guardModel,
  visNetConfigurator: visNetConfigurator,
  guardConfigurator: guardConfigurator,
  visNetModelId: visNetModelId,
  guardModelId: guardModelId,
};

const loadEnvironmentConfig = () => {
  switch (process.env.REACT_APP_TARGET_ENV ?? process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "beta":
      return beta;
    default:
      return dev;
  }
};

const config = loadEnvironmentConfig();

export default config;
