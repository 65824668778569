import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography';

interface Props {
	text: string;
	show: boolean;
	mode: ToastMode;
	autoHide?: boolean;
	onAutohide?: () => void;
}

export enum ToastMode {
	Success,
	Warning,
	Error,
	Info
}

export function MessageToast(props: Props) {

	const [showToast, setShowToast] = useState(false);
	const [toastBackgroundClassname, setToastBackgroundClassname] = useState("bg-success");
	const [toastIcon, setToastIcon] = useState(faCheckCircle);

	useEffect(() => {
		setShowToast(props.show);
	}, [props.show])

	useEffect(() => {
		if (props.mode === ToastMode.Success) { setToastBackgroundClassname("bg-success"); setToastIcon(faCheckCircle); }
		else if (props.mode === ToastMode.Warning) { setToastBackgroundClassname("bg-warning"); setToastIcon(faExclamationTriangle); }
		else if (props.mode === ToastMode.Error) { setToastBackgroundClassname("bg-danger"); setToastIcon(faExclamationTriangle); }
		else if (props.mode === ToastMode.Info) { setToastBackgroundClassname("bg-secondary"); setToastIcon(faInfoCircle); }
	}, [props.mode])

	function handleToastClose() {
		setShowToast(false);
		props.onAutohide && props.onAutohide();
	}

	return (
		<Toast onClose={handleToastClose} show={showToast} delay={5000} autohide={props.autoHide} className={`${toastBackgroundClassname} mb-0 mr-2 text-light`}>
			<Toast.Body className="d-flex  align-items-center  py-1">
				<FontAwesomeIcon icon={toastIcon} className="mr-3" style={{ fontSize: "2em" }} />
				<Typography variant="body2" component="p"  align="left">{props.text}</Typography>
			</Toast.Body>
		</Toast>

	);
}
