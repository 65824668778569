import React from 'react';
import Button from '@material-ui/core/Button';
import MuiSnackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
	message?: string;
	open: boolean;
	onClose: () => void;
	actionText?: string;
	onActionClick?: () => void;
	children?: React.ReactElement;
}

export function Snackbar(props: Props) {

	const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		props.onClose();
	};

	return (
		<div>

			<MuiSnackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={props.open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={props.message}
				action={
					<React.Fragment>
						{props.actionText
							&& props.onActionClick
							&& <Button color="primary" size="small" onClick={props.onActionClick}>
								{props.actionText}
							</Button>
						}
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			>
				{props.children}
			</MuiSnackbar>
		</div>
	);
}
