import React from 'react';
import config from '../../../config';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import { Customer } from '../interfaces/Customer';
import { CustomerRegionHierarchy } from '../interfaces/CustomerRegionHierarchy';

interface Props {
	onAuthError?: () => void;
}
export default class CustomerApis extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({onAuthError: this.props.onAuthError});

	private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/Customers/`;
    
    getCustomers = (): Promise<Customer[]> => {
        
        return this.apiResponseHandler.get(CustomerApis.BASE_URL)
          .then(data => {
              if (!data) {
                  return [];
              }
              if (!Array.isArray(data)) {
                return [];
              }
              return data
          });
    }

    getCustomerRegionHierarchy = (): Promise<CustomerRegionHierarchy[]> => {
        const apiAddress = `${CustomerApis.BASE_URL}CustomerRegionHierarchy`;
        return this.apiResponseHandler.get(apiAddress);
    }

}
