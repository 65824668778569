import { useQuery } from "react-query";
import { ApiContext } from "../Contexts/ApiContext";
import { useContext } from "react";

export function useLicenceAreas(substationId?: number) {
    const apiContext = useContext(ApiContext);
    return useQuery(
        ["licenceAreas", substationId],
        async () => {
            return await apiContext.licenceAreaAPIs.getDefaultLicenceArea(substationId);
        },
        {
            enabled: substationId !== undefined
        }
    );
}