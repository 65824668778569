import React, { useState, useEffect } from "react";
import { TokenHelper } from "../Shared/TokenHelper";
import TokenRefresher from "../Shared/TokenRefresher";
import { JwtManager } from "../Shared/JwtManager";
import { Operations, Entity } from '../Shared/interfaces/ClaimsControl'

export interface IAuthContext {
	userIsAuthenticated: boolean;
	setUserIsAuthenticated?: any;
	setUserIsNotAuthenticated?: any;
	checkTokenIsValid?: any;
	logout: () => void;
	canI: (operation: keyof Operations, entity: Entity, customerId?: number) => boolean | undefined;
	getUserId: () =>  string;
}

export const AuthContext = React.createContext({} as IAuthContext);

const checkTokenInterval = 60000;

const tokenHelper = new TokenHelper({});
const tokenRefresher = new TokenRefresher({});
const tokenManager = new JwtManager({});

export function AuthContextProvider(props: any) {
	const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

	useEffect(() => {

		function updateTokenValidity() {

			checkTokenIsValid().then(tokenIsValid => {
				if (tokenIsValid) setUserIsAuthenticated(true);
				else setUserIsAuthenticated(false);
			});

		}

		if (!tokenHelper.tokenIsValid()) setUserIsAuthenticated(false);
		else setUserIsAuthenticated(true);
		setInterval(updateTokenValidity, checkTokenInterval)
	}, [])

	function checkTokenIsValid(): Promise<boolean> {
		if (tokenHelper.tokenIsValid()) {
			return Promise.resolve(true);
		}

		// Try refreshing if a refresh token is available
		if (!tokenHelper.refreshTokenExists()) {
			return Promise.resolve(false);
		}

		return tokenRefresher
			.refreshToken()
			.then(() => tokenHelper.tokenIsValid())
			.catch(() => false)
	}

	function logout() {
		tokenManager.removeToken();
		setUserIsAuthenticated(false);
	}
	
	function canI(operation: keyof Operations, entity: Entity, customerId?: number): boolean | undefined {
		const tokenPayload = tokenManager.getPayload();
		
		if(tokenPayload && tokenPayload.IsAdmin === "IsAdmin") return true;

		if(customerId && entity === "customer" && operation === "view")
			return tokenPayload && tokenPayload.CustomerView?.includes(customerId.toString());
		
	}

	function getUserId(): string{
		return tokenManager.getPayload()?.UserId;
	}
	
	return (
		<AuthContext.Provider
			value={{
				userIsAuthenticated: userIsAuthenticated,
				setUserIsAuthenticated: () => setUserIsAuthenticated(true),
				setUserIsNotAuthenticated: () => setUserIsAuthenticated(false),
				checkTokenIsValid: checkTokenIsValid,
				logout: logout,
				canI: canI,
				getUserId:getUserId
			}}>
			{props.children}
		</AuthContext.Provider>
	);

}
