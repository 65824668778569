import React from "react";
import { ApiHandlerPureComponent } from "./ApiHandlerPureComponent";
import config from "../../../config";
import { PartialSubstation } from "../interfaces/PartialSubstation";

interface Props {
  onAuthError?: () => void;
}

export default class SubstaionAPIs extends React.PureComponent<Props> {
  private apiResponseHandler = new ApiHandlerPureComponent({
    onAuthError: this.props.onAuthError,
  });
  private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/assets/substationlist/customer/`;

  getPartialSubstations = (
    customerId: number,
    substationName: string
  ): Promise<PartialSubstation[]> => {
    const apiAddress = `${SubstaionAPIs.BASE_URL}${customerId}?name=${substationName}&serialNumber=${substationName}`;
    return this.apiResponseHandler.get(apiAddress);
  };
}
