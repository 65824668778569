import React, { useContext, PropsWithChildren } from "react";
import { AuthContext } from './AuthContext';
import CustomerAPIs from '../Shared/apiHandlers/CustomerAPIs';
import SimpleSubstationApis from "../Shared/apiHandlers/SimpleSubstationAPIs";
import VisNetAPIs from "../Shared/apiHandlers/VisNetAPIs";
import RequestAPIs from "../Shared/apiHandlers/RequestAPIs";
import SubstaionAPIs from "../Shared/apiHandlers/SubstationAPIs";
import AssetsAPIs from "../Shared/apiHandlers/AssetsAPIs";
import LicenceAreasApis from "../Shared/apiHandlers/LicenceAreasAPIs";
import DistrictAPIs from "../Shared/apiHandlers/DistrictsAPIs";

interface ApiContextType {
	customerApis: CustomerAPIs;
	simpleSubstationApis: SimpleSubstationApis;
	visNetAPIs: VisNetAPIs;
	requestAPIs: RequestAPIs;
	substationAPIs: SubstaionAPIs;
	assetsApis: AssetsAPIs;
	licenceAreaAPIs: LicenceAreasApis;
	districtAPIs: DistrictAPIs;
}

function initialiseApis(onAuthError?: () => void): ApiContextType {
	return {
		customerApis: new CustomerAPIs({ onAuthError: onAuthError }),
		simpleSubstationApis: new SimpleSubstationApis({onAuthError: onAuthError}),
		visNetAPIs: new VisNetAPIs({onAuthError: onAuthError}),
		requestAPIs: new RequestAPIs({onAuthError: onAuthError}),
		substationAPIs: new SubstaionAPIs({onAuthError: onAuthError}),
		assetsApis: new AssetsAPIs({onAuthError: onAuthError}),
		licenceAreaAPIs: new LicenceAreasApis({onAuthError: onAuthError}),
		districtAPIs: new DistrictAPIs({onAuthError: onAuthError})
	}
}

/**
 * Context containing initialised API handlers. Use this to get API handlers without having to initialise them in each dependent component.
 *
 * @example
 * // Use in function components
 * const MyFunctionComponent = (props) => {
 *     const apiContext = useContext(ApiContext);
 *     const asset = apiContext.assetApi.getAsset(123);
 * }
 *
 * // Use in class components
 * const MyClassComponent extends React.Component {
 *     static contextType = ApiContext; // Set the component's context to this
 *     context!: React.ContextType<typeof ApiContext>; // Set the context type to help TypeScript
 *
 *     const asset = apiContext.assetApi.getAsset(123);
 * }
 */
export const ApiContext = React.createContext<ApiContextType>({} as ApiContextType);

export const ApiContextProvider = (props: PropsWithChildren<unknown>) => {
	const authContext = useContext(AuthContext);

	return (
		<ApiContext.Provider value={initialiseApis(authContext.logout)}>
			{props.children}
		</ApiContext.Provider>
	);
};
