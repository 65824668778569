import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Layout } from './Layout';
import { Home } from './pages/Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faHome, faSitemap, faChartLine, faBolt } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from './components/Contexts/AuthContext'
import {RouteComponentProps} from "react-router";

export interface Props {
    children?: React.ReactNode;
    location?: any;
}

interface Page {
    title: string;
    path: string;
    component: any;
    routes?: any;
    icon: (iconProps?: any) => JSX.Element;
    isMenuItem?: boolean;
}

export const usePages = (): Page[] => {
    const authContext = useContext(AuthContext);

    const [pages, setPages] = useState<Page[]>([]);

    useEffect(() => {
        var pagesSet = new Set<Page>();

        pagesSet.add({
            title: ("Simple Substation Install") || "",
            path: '/',
            component: Home,
            icon: (iconProps?: any) => <FontAwesomeIcon icon={faHome} {...iconProps} />,
            isMenuItem: true
        });

        setPages(Array.from(pagesSet));
    
    }, [authContext]);

    return pages;

}

export const usePageIcon = (componentName: string) => {
    const pages = usePages();
    const page = pages.find((page: any) => page.component.name === componentName);
    return page?.icon();

}

export const usePage = (componentName: string) => {
    const pages = usePages();
    const page = pages.find((page: any) => page.component.name === componentName);
    return page;
}

export function DocumentTitle(props: { title: string }) {
    document.title = `${props.title} | Simple Substation Register`;
    return null;
}

function PrivateRoute({ component: Component, title, ...rest }: Page) {
    return (
        <Route {...rest} 
            render={(props: RouteComponentProps) => (
                <React.Fragment>
                    <DocumentTitle title={title}/>
                    <Component {...props}/>
                </React.Fragment>
            )}
        />
    )
};

export function Routes() {
    const pages = usePages();

    return <BrowserRouter>
        <Layout>
            {pages.map((page: any) => <PrivateRoute exact path={page.path} component={page.component as any} key={page.path} {...page} />)}
        </Layout>
    </BrowserRouter>
}

export default Routes;
