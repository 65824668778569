import { Dropdown, DropdownProps } from "primereact/dropdown";
import { LicenceArea } from "./Shared/interfaces/CustomerRegionHierarchy";
import { useLicenceAreas } from "./Data/useLicenceAreas";
import { CircularProgress, TextField } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { MessageToast, ToastMode } from "./Shared/MessageToast";

interface Props {
    licenceAreas: LicenceArea[];
    onSelect: (licenceArea?: LicenceArea) => void;
    selectedValue: LicenceArea | undefined;
    substationId?: number;
}

export function LicenceAreaDropdown(props: Props & DropdownProps) {
    const { onSelect, licenceAreas, selectedValue, substationId, ...rest } = props;

    const defaultLicenceArea = useLicenceAreas(props.substationId);
    
    function handleChange(e: DropdownProps) {
        onSelect(e.value);
    }
    if (defaultLicenceArea.isLoading) {
        return <CircularProgress size={24} />
    }
    if (defaultLicenceArea.error) {
        return <MessageToast text={'Error getting customers'} mode={ToastMode.Error} show />
    }
    if (substationId && !defaultLicenceArea.data) {
        return <InputText className="w-100" value="No Licence Area found for the selected substation" disabled />
    }
    else if (substationId) {
        return <InputText className="w-100" value={defaultLicenceArea.data?.name} disabled />
    }
    return <div className="d-flex justify-content-start">
        <div className="d-flex flex-column">
            <Dropdown
                placeholder={"Please select a Licence Area"}
                options={licenceAreas}
                optionLabel="name"
                filter
                filterBy="name"
                value={selectedValue}
                onChange={handleChange}
                {...rest}
                showClear={true}
            >
            </Dropdown>
        </div>
    </div>
}