import { useQuery } from "react-query";
import { ApiContext } from "../Contexts/ApiContext";
import { useContext } from "react";

export function useAssets(substationId: number) {
    const apiContext = useContext(ApiContext);
    return useQuery(
        ["Assets", substationId],
        async () => {
            return await apiContext.assetsApis.getAssets(substationId);
        }
    );
}