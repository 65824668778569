import React from 'react';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import config from '../../../config';
import { District } from '../interfaces/District';

interface Props {
    onAuthError?: () => void;
}

export default class DistrictAPIs extends React.PureComponent<Props> {
    private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });
    private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/Districts/parent/asset/`;

    getDefaultDistrict = (substationId?: number): Promise<District> => {

        const apiAddress = `${DistrictAPIs.BASE_URL}${substationId}`;
        return this.apiResponseHandler.get(apiAddress);
    }
}