import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Container } from 'react-bootstrap';
import { FormRow } from './FormRow';

export interface FormItem {
	label: string;
	value: string | React.ReactNode;
	action?: React.ReactNode | undefined;
	hide?: boolean;
}

interface Props {
	classNames?: string;
	dataList?: FormItem[];
	emptyMessage?: string;
}

export function FormContainer(props: Props) {
	const displayActionColumn = props.dataList?.some((dataItem: FormItem) => dataItem.action !== undefined);

	if (props.dataList && props.dataList.length > 0 )
	return (
		<Container fluid className={props.classNames}>
		  {props.dataList.map((dataItem: FormItem) => {
			if (!dataItem.hide) {
			  return (
				<FormRow
				  key={`dataDisplayRow-${dataItem.label}`}
				  label={dataItem.label}
				  value={dataItem.value}
				  displayActionCell={displayActionColumn}
				  action={dataItem.action}
				/>
			  );
			}
			return null; 
		  })}
		</Container>
	  );
	else
		return <Typography variant="body2" color="textSecondary" >
			{props.emptyMessage}
		</Typography>

}
