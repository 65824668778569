import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Device} from "../Shared/interfaces/Device"

export const visNetModel = "VisNet Hub";
export const guardModel = "Guard";
export const visNetModelId = 100004;
export const guardModelId = 100123;

export var Devices: Device []  = [{
    name: "Guard",
    component: "guard",
    description: "Commission Guard",
    icon: <FontAwesomeIcon icon="shield-alt" size="5x"/>,
    model: guardModel
},
{
    name: "VisNet Hub",
    component: "visnet",
    description: "Commission VisNet Hubs",
    icon: <FontAwesomeIcon icon="plug" size="5x"/>,
    model: visNetModel
}]