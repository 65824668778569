import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import logo from '../images/EAWhite.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.eaPurple.main
        },
        appName: {
            color: theme.palette.eaPurple.contrastText,
            fontFamily: 'Roboto Light',
            fontSize: "2em"
        },
        logoImage: {
            height: "40px",
            width: "70px",
            borderRight: "1px solid white",
            marginRight: "10px"

        }
    }),
);

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar
                elevation={0}
                className={classes.appBar}
            >
                <Toolbar>
                    <img alt="EA Technology" src={logo} className={classes.logoImage} />
                    <Typography className={classes.appName}>{"Simple Substation Register"}</Typography>
                </Toolbar>
            </AppBar>

        </div>
    );
}
