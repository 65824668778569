import { Dropdown, DropdownProps } from "primereact/dropdown";
import { District } from "./Shared/interfaces/CustomerRegionHierarchy";

interface Props {
    districts: District[];
    onSelect: (district?: District) => void;
    selectedValue: District | undefined;
}

export function DistrictsDropdown(props: Props & DropdownProps) {

    const { onSelect, districts, ...rest } = props;
    function handleChange(e: DropdownProps) {
        onSelect(e.value);
    }

    return <div className="d-flex justify-content-start">
        <div className="d-flex flex-column">

            {
                <Dropdown
                    placeholder={"Please select a District"}
                    options={districts}
                    optionLabel="name"
                    filter
                    filterBy="name"
                    value={props.selectedValue}
                    onChange={handleChange}
                    {...rest}
                >
                </Dropdown>
            }
        </div>
    </div>
}