import React from 'react';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
	title: string;
	action?: React.ReactNode;
	className?: string;
	children?: React.ReactNode;
	loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.lightBackground.light,
		color: 'inherit',
	},
	header: {
		backgroundColor: theme.palette.lightBackground.main,
	},
	content: {
		paddingTop: "0px",
		paddingBottom: theme.spacing(0),
		"&:last-child": {
			paddingBottom:  theme.spacing(1),
		  }
	},
	divider: {
		backgroundColor: theme.palette.grey[200]
	}
}));

export function Card(props: Props) {
	const classes = useStyles();

	const card = <MuiCard className={clsx(classes.root, "card-bg-secondary", props.className)} variant="outlined"  >
		<CardHeader className={classes.header} title={props.title} titleTypographyProps={{ variant: "subtitle1" }} action={props.action} />
		<Divider />
		<CardContent className={classes.content} >
			{props.children}
		</CardContent>
	</MuiCard >;

	if (props.loading)
		return <Skeleton variant="rect" width="100%" >
			{card}
		</Skeleton>

	else return card;
}