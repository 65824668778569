import React from 'react';
import config from '../../../config';
import { ApiHandlerPureComponent } from './ApiHandlerPureComponent';
import { Asset } from '../interfaces/Asset';

interface Props {
	onAuthError?: () => void;
}

export default class VisNetAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.META_API}/api/VisNet/`;

	getVisNetBySerialAndCustomer = (serial: string, customerId: number): Promise<Asset> => {
		const apiAddress = `${VisNetAPIs.BASE_URL}BySerial/${serial}/customer/${customerId}`
		
		return this.apiResponseHandler.get(apiAddress);
	}
}
